import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { Position } from '@capacitor/geolocation';

import { environment } from '../../environments/environment';

import { ArrivedMqttService } from './mqtt/arrived-mqtt.service';
import { MqttConnectionService } from './mqtt/mqtt-connection.service';
import { RouteUpdateAcceptedMqttService } from './mqtt/route-update-accepted-mqtt.service';
import { RouteUpdateMqttService } from './mqtt/route-update-mqtt.service';
import { TelemetryUpdateMqttService } from './mqtt/telemetry-update-mqtt.service';

import { RouteUpdate } from './mqtt/models/route-update';
import { MqttResponse } from './mqtt/models/mqtt-response';
import { MqttResultType } from './mqtt/types/mqtt-result-type';
import { TelemetryUpdate } from './mqtt/models/telemetry-update';

import { GeolocationService } from './native/geolocation.service';

import { SharedSettingService } from './shared-setting.service';
import { Wgs2Htz } from './wgs-2-htz';

import { TimeUtil } from '../utils/time-util';
import { PolylineUtil } from '../utils/polyline-util';

import { DummyTelemetryData } from '../components/modal-debug/models/DummyTelemetryData';
import { NgIf } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TaxiStatusService {
  /** テレメトリ情報MQTTでMDGWに送信するメッセージ */
  telemetry: TelemetryUpdate;

  /** 目的地緯度 */
  destX: number = 0.0;
  /** 目的地経度 */
  destY: number = 0.0;
  /** 到着フラグ */
  isArrived: boolean = true;
  /** 同一目的地フラグ */
  isSameDist: boolean = false;
  /** 同一地点カウント */
  hackCount: number = 0;
  /** ルート情報受信フラグ */
  isAccepted: boolean = false;
  /** 到着スキップフラグ DT画面の到着ボタンが押されたか */
  isDestination: boolean = false;
  /** 緯度経度→平面直角座標変換 */
  wgs2Htz: Wgs2Htz;

  /** 到着判断時の閾値 */
  readonly threshold: number = environment.setting.threshold;

  /** 停車扱いとする車速(km/h) */
  readonly STOP_SPEED = environment.setting.stopSpeed;
  /** 走行中と判定する最低速度(km/h) */
  readonly MOVING_SPEED = environment.setting.movingSpeed;
  /** 同一目的カウントの上限 */
  readonly WAIT_COUNT_FOR_SAME_DIST = environment.setting.waitCountForSameDist;
  /** ルート情報取得時のエラー時のステータスコード */
  readonly STATUS_DENA_ERROR = environment.setting.statusDenaError;
  /** ルート情報取得時の正常時のステータスコード */
  readonly STATUS_DENA_ACCEPT = environment.setting.statusDenaAccept;
  /** 車両の方向を取得するかの閾値(m) */
  readonly HEADING_CALC_DISTANCE = environment.setting.headingCalcDistance;

  private subscriptions = new Subscription();


  /** (デバッグ用)セットルートのポリライン設定 */
  polyLatLngs: number[][];
  polyLatLngsIdx: number = -1;

  readonly MAXITERS = 20;
  readonly DOUBLE_MINUS_1_0 = -1.0;
  readonly DOUBLE_1_0E_12 = 1.0e-12;

  readonly DOUBLE_1_0 = 1.0;
  readonly DOUBLE_2_0 = 2.0;
  readonly DOUBLE_3_0 = 3.0;
  readonly DOUBLE_4_0 = 4.0;
  readonly DOUBLE_16_0 = 16.0;
  readonly DOUBLE_180_0 = 180.0;
  readonly DOUBLE_360_0 = 360.0;
  readonly DOUBLE_PI_DIVIDE_180_0 = Math.PI / this.DOUBLE_180_0;

  /** WGS84 major axis. */
  readonly DOUBLE_6378137_0 = 6378137.0;
  /** WGS84 semi-major axis. */
  readonly DOUBLE_6356752_3142 = 6356752.3142;

  readonly DOUBLE_F = (this.DOUBLE_6378137_0 - this.DOUBLE_6356752_3142) / this.DOUBLE_6378137_0;
  readonly DOUBLE_1_0_MINUS_F = this.DOUBLE_1_0 - this.DOUBLE_F;

  constructor(
    private arrivedMqttService: ArrivedMqttService,
    private mqttConnectionService: MqttConnectionService,
    private routeUpdateAcceptedMqttService: RouteUpdateAcceptedMqttService,
    private routeUpdateMqttService: RouteUpdateMqttService,
    private telemetryUpdateMqttService: TelemetryUpdateMqttService,
    private geolocationService: GeolocationService,
    private sharedSettingService: SharedSettingService
  ) {
  }

  /**
   * 開始します。
   */
  async start() {
    const vehicle = this.sharedSettingService.getVehicle();

    this.wgs2Htz = new Wgs2Htz();

    this.telemetry = this.telemetryUpdateMqttService.createMessage(vehicle.vin);
    this.telemetry.taxi_status = vehicle.taxiStatus;

    await this.startGeolocation();
    await this.startMqtt();
  }

  /**
   * 停止します。
   */
  async stop() {
    this.subscriptions.unsubscribe();
    await this.stopGeolocation();
  }

  /**
   * 位置情報の取得を開始します。
   */
  private async startGeolocation() {
    await this.geolocationService.start();

    this.subscriptions.add(this.geolocationService.watchPosition().subscribe((position: Position) => {
      if(position !== null){
        this.setPosition(position);
      }
    }));

    this.subscriptions.add(this.geolocationService.watchPositionError().subscribe((err: any) => {
      if(err){
        this.geolocationService.stop();
        this.geolocationService.start();
      }
    }));
  }

  /**
   * 位置情報の取得を停止します。
   */
  private async stopGeolocation() {
    await this.geolocationService.stop();
  }

  /**
   * MQTT を開始します。
   */
  private async startMqtt() {
    this.subscriptions
      .add(this.mqttConnectionService.interval().subscribe(() => {
        this.publishTelemetryUpdate();
      }))
      .add(this.arrivedMqttService.message().subscribe(() => {
        // メッセージが渡されるがタイムスタンプのみのため無条件にTRUE
        this.isDestination = true;
      })).add(this.routeUpdateMqttService.message().subscribe((response) => {
        this.setRouteUpdate(response);
      }));
  }

  /** 位置情報取得時のコールバック関数 */
  private setPosition = (position: Position) => {
    const coord = position.coords;

    // 車速を設定
    this.setSpeed(position);
    // 車両の方向を設定
    this.setHeading(position);

    this.telemetry.gpstime = TimeUtil.toDateString(new Date(position.timestamp));
    this.telemetry.create_time = TimeUtil.getDateString();

    this.telemetry.lat = coord.latitude;
    this.telemetry.lng = coord.longitude;

    // GPSからの現在位置が目的地の閾値以内か判定
    this.isArrived = this.isArrival(this.telemetry.lat, this.telemetry.lng, this.destX, this.destY, this.threshold)
  }

  /** 閾値以上移動している場合車両の方向を更新する */
  private setHeading(position: Position) {
    const coord = position.coords;

    // 前回取得した位置情報の平面直角座標
    const previous = this.wgs2Htz.convertWgs2Htz(this.telemetry.lat, this.telemetry.lng);
    // 今回取得した位置情報の平面直角座標
    const current = this.wgs2Htz.convertWgs2Htz(coord.latitude, coord.longitude);
    // 車両方向を算出するか判定するための前回と現在の距離
    const dist = Math.hypot(current.outY - previous.outY, current.outX - previous.outX);
    // 前回からの移動距離と車速が閾値以上の場合のみ方向を更新する
    if (dist > this.HEADING_CALC_DISTANCE && this.telemetry.speed >= this.STOP_SPEED) {
      this.telemetry.direction = coord.heading;
    }
  }

  /** 速度をメートル/秒からキロメートル/時に変換して更新 */
  private setSpeed(position: Position) {
    const coord = position.coords;

    this.telemetry.speed = coord.speed * 3.6;
  }

  /** ルート情報取得時に内容のチェックを行うコールバック関数 */
  private setRouteUpdate = (message: MqttResponse<RouteUpdate>) => {
    console.debug("setRouteUpdate():",message);
    let requestIdDena: string;
    let carVinDena: string;
    let jsonPoly: string;
    let stopId: string;
    try {
      if (message.result === MqttResultType.FAIL) {
        const alert = 'Received Polyline is incorrect format!'
        this.publishRouteUpdateAccepted(alert, this.STATUS_DENA_ERROR, requestIdDena, carVinDena, jsonPoly, stopId);

        return;
      }

      const jsonmsg = message.message;

      if ('polyline' in jsonmsg && 'request_id' in jsonmsg && 'vin' in jsonmsg && jsonmsg.polyline != null && 'dest_lat' in jsonmsg && 'dest_lng' in jsonmsg && 'stop_id' in jsonmsg) {
        requestIdDena = jsonmsg.request_id;
        carVinDena = jsonmsg.vin;
        jsonPoly = jsonmsg.polyline;
        stopId = jsonmsg.stop_id;
        if (jsonPoly.length > 0) {
          try {

            // (デバッグ用)セットルートのポリライン設定の初期化
            this.polyLatLngs = PolylineUtil.toLatLngs(jsonPoly);
            this.polyLatLngsIdx = -1;
            // (AD車両テスト用)ルート情報取得時にポリラインを設定
            this.updateRouteStatus();

            this.isAccepted = true;
            //ルート情報が正常なら到着判断を実行
            this.routeArrayCallback(PolylineUtil.toLatLngs(jsonPoly), jsonmsg.dest_lat, jsonmsg.dest_lng);
            const alert = 'Received Polyline is accepted'
            this.publishRouteUpdateAccepted(alert, this.STATUS_DENA_ACCEPT, requestIdDena, carVinDena, jsonPoly, stopId);
          } catch (e) {
            const alert = 'Received Polyline is incorrect format!'
            this.publishRouteUpdateAccepted(alert, this.STATUS_DENA_ERROR, requestIdDena, carVinDena, jsonPoly, stopId);
          }
        } else {
          const alert = 'Received Polyline is empty!'
          this.publishRouteUpdateAccepted(alert, this.STATUS_DENA_ERROR, requestIdDena, carVinDena, jsonPoly, stopId);
        }
      } else {
        const alert = 'Json from ADGW does not have polyline or other key!'
        this.publishRouteUpdateAccepted(alert, this.STATUS_DENA_ERROR, requestIdDena, carVinDena, jsonPoly, stopId);
      }
    } catch (e) {
      const alert = 'Received Polyline is incorrect format!'
      this.publishRouteUpdateAccepted(alert, this.STATUS_DENA_ERROR, requestIdDena, carVinDena, jsonPoly, stopId);
    }
  }

  /**
   * ルート情報取得時の到着判断.
   * 
   * @param latLngs 緯度経度のリスト
   * @param destLat 目的地の緯度
   * @param destlng 目的地の経度
   */
  private routeArrayCallback(latLngs: number[][], destLat: number, destLng: number) {
    // ルート情報の目的地と中間地点の位置情報を取得する
    const midId: number = Math.floor(latLngs.length / 2);
    const dest = {
      lat: destLat,
      lng: destLng
    };
    const midlatLng = latLngs[midId];

    const mid = {
      lat: midlatLng[0],
      lng: midlatLng[1]
    };

    // 目的地と中間地点の両方と到着判断を行う
    this.isSameDist = (this.isArrival(this.telemetry.lat, this.telemetry.lng, dest.lat, dest.lng, this.threshold)
      && this.isArrival(this.telemetry.lat, this.telemetry.lng, mid.lat, mid.lng, this.threshold));

    this.destX = dest.lat;
    this.destY = dest.lng;
  }

  /** ルート情報の取得を応答MQTTでパブリッシュする
   *  (NonADでは不正時のみ)
   */
  private publishRouteUpdateAccepted(alert: string, status: number, requestIdDeNA: string, carVinDeNA: string, polylineDeNA: string, stopId: string) {
    try {
      const message = this.routeUpdateAcceptedMqttService.createMessage(status, requestIdDeNA, carVinDeNA, polylineDeNA, stopId);
      this.routeUpdateAcceptedMqttService.publishMessage(message);

      console.log(alert);
    } catch (e) {
      console.error(e);
    }
  }

  /** 2点間の距離が閾値の2乗の範囲内か判定する */
  private isArrival(x1: number, y1: number, x2: number, y2: number, threshold: number): boolean {
    const current = this.wgs2Htz.convertWgs2Htz(x1, y1);
    const dest = this.wgs2Htz.convertWgs2Htz(x2, y2);
    const dist = Math.pow(current.outX - dest.outX, 2) + Math.pow(current.outY - dest.outY, 2);

    return (dist < threshold * threshold);
  }

  /** テレメトリー情報を算出しパブリッシュする */
  private publishTelemetryUpdate() {
    try {
      switch (this.telemetry.taxi_status) {
        case 0:
          if ((this.isArrived && this.telemetry.speed < this.STOP_SPEED) || this.isSameDist || this.isDestination) {
            if (this.hackCount < this.WAIT_COUNT_FOR_SAME_DIST && (this.isSameDist || this.isDestination)) {
              this.telemetry.taxi_status = 0;
              this.telemetry.shift_P = false;
              this.telemetry.shift_D = true;
              this.telemetry.ad_arrival = 0;
              this.hackCount++;
            } else {
              this.telemetry.taxi_status = 1;
              this.isSameDist = false;
              this.isDestination = false;
              this.telemetry.shift_P = true;
              this.telemetry.shift_D = false;
              this.telemetry.ad_arrival = 1;
            }
          }

          if (this.isAccepted) {
            // 走行中のリルート（目的地変更）時は、タクシーステータスを '2'（送迎準備完了） に戻す
            this.isAccepted = false;
            this.telemetry.taxi_status = 2;
            this.hackCount = 0;
            this.telemetry.shift_P = false;
            this.telemetry.shift_D = true;
            this.telemetry.ad_arrival = 0;
          }
          break;
        case 1:
          if (this.isSameDist || this.isAccepted || this.isDestination) {
            this.isAccepted = false;
            this.telemetry.taxi_status = 2;
            this.telemetry.shift_P = false;
            this.telemetry.shift_D = true;
            this.telemetry.ad_arrival = 0;
            if (this.isSameDist || this.isDestination) {
              this.hackCount = 0;
            }
          }
          break;
        case 2:
          if (this.telemetry.speed > this.MOVING_SPEED || this.isSameDist || this.isDestination) {
            this.telemetry.taxi_status = 0;
            this.telemetry.shift_P = false;
            this.telemetry.shift_D = true;
            this.telemetry.ad_arrival = 0;
            if (this.hackCount < this.WAIT_COUNT_FOR_SAME_DIST) {
              this.telemetry.taxi_status = 2;
              this.hackCount++;
            } else {
              this.hackCount = 0;
            }
          }

          // タクシーステータスが '2'（送迎準備完了） の時に、リルート（目的地変更）された場合を想定し、ルート情報受信フラグを初期化する
          this.isAccepted = false;
          break;
      }
    } catch (e) {
      console.error(e);
    }

    try {
      // navigator.geolocation.watchPosition では移動しないと gpstime が更新されないため、強制的に現在日時を設定
      this.telemetry.gpstime = TimeUtil.getDateString();
      this.telemetry.soc = this.sharedSettingService.getSoc();

      // this.mqttPub.sendTopic(this.telemetry.toMessege(), this.telemetry.vin + this.TELEMETRY_UPDATE_TOPIC);
      this.telemetryUpdateMqttService.publishMessage(this.telemetry);
    } catch (e) {
      console.error(e);
    }
  }

  /** (AD車両テスト用)ルートステータスの更新 */
  public updateRouteStatus(){ 
    let tmpPolyLatLngs: number[][];
    tmpPolyLatLngs = this.polyLatLngs;

    // 現在位置からのポリラインを切り出す
    if(this.polyLatLngsIdx > 0 && this.polyLatLngsIdx < this.polyLatLngs.length && this.polyLatLngs != null){
      tmpPolyLatLngs = this.polyLatLngs.slice(this.polyLatLngsIdx);
    }

    let polyline = PolylineUtil.toPolyline(tmpPolyLatLngs);

    if(this.polyLatLngsIdx === this.polyLatLngs.length){
      // ポリライン移動により目的地に到着したとき、"route_status","route_status_ETA"をnullにする
      polyline = null;
    }

    this.telemetry.route_status = polyline;
    this.telemetry.route_status_ETA = polyline;
  }

  /** (AD車両テスト用)ドアの開閉状態更新 */
  public setDoorLock(doorLock: number){ 
    if(doorLock === 1){
      this.telemetry.door_lock = 3;
      this.telemetry.rear_left_door_open = 0;
    }
    else{
      this.telemetry.door_lock = 0;
      this.telemetry.rear_left_door_open = 1;
    }
  }

  /** (AD車両テスト用)シートベルトの開閉状態更新 */
  public setSeatbelt(seatbelt: number){ 
    this.telemetry.second_right_belt_lock = seatbelt;
    this.telemetry.second_center_belt_lock = seatbelt;
    this.telemetry.second_left_belt_lock = seatbelt;
    this.telemetry.third_right_belt_lock = seatbelt;
    this.telemetry.third_center_belt_lock = seatbelt;
    this.telemetry.third_left_belt_lock = seatbelt;
  }

  /** ダミーデータ. */
  public setDummy(data: DummyTelemetryData){
    let position:Position = {
      timestamp: Date.now(),
      coords: {
        latitude: data.lat,
        longitude: data.lng,
        accuracy: 0,
        altitudeAccuracy: 0,
        altitude: 0,
        speed: data.speed,
        heading: data.direction
      }
    };
    this.setPosition(position);
  }

  /** (デバッグ用)セットルートのポリライン設定. */
  public setPolyLatLngs(step: number){

    if (this.polyLatLngs != null) {

      let tmpLat = 0;
      let tmpLng = 0;
      let tmpSpeed = 0;
      this.polyLatLngsIdx += step;
      if (this.polyLatLngsIdx >= this.polyLatLngs.length) {
        this.polyLatLngsIdx = this.polyLatLngs.length;
        tmpLat = this.destX;
        tmpLng = this.destY;
        tmpSpeed = 0;
      } else if (this.polyLatLngsIdx <= -1) {
        this.polyLatLngsIdx = 0;        
        tmpLat = this.polyLatLngs[this.polyLatLngsIdx][0];
        tmpLng = this.polyLatLngs[this.polyLatLngsIdx][1];
        tmpSpeed = 0;
      } else {
        tmpLat = this.polyLatLngs[this.polyLatLngsIdx][0];
        tmpLng = this.polyLatLngs[this.polyLatLngsIdx][1];
        tmpSpeed = 5;
      }

      let tmpDir = this.computeDirection(this.telemetry.lat, this.telemetry.lng, tmpLat, tmpLng);

      let dummyTelemetryData:DummyTelemetryData = {
        lat: tmpLat,
        lng: tmpLng,
        direction: tmpDir,
        speed: tmpSpeed
      };
      // 現在地点からのポリラインを設定
      this.updateRouteStatus();
      this.setDummy(dummyTelemetryData);
    }
  }

  /** from緯度経度から見たto緯度経度の方位角を取得する. */
  private computeDirection(fromLat: number, fromLon: number, toLat: number, toLon: number) {
    // Based on http://www.ngs.noaa.gov/PUBS_LIB/inverse.pdf
    // using the "Inverse Formula" (section 4)

    // Convert lat/long to radians
    const lat1 = fromLat * (this.DOUBLE_PI_DIVIDE_180_0);
    const lat2 = toLat * (this.DOUBLE_PI_DIVIDE_180_0);
    const lon1 = fromLon * (this.DOUBLE_PI_DIVIDE_180_0);
    const lon2 = toLon * (this.DOUBLE_PI_DIVIDE_180_0);

    const l = lon2 - lon1;

    const u1 = Math.atan(this.DOUBLE_1_0_MINUS_F * Math.tan(lat1));
    const u2 = Math.atan(this.DOUBLE_1_0_MINUS_F * Math.tan(lat2));

    const cosU1 = Math.cos(u1);
    const cosU2 = Math.cos(u2);
    const sinU1 = Math.sin(u1);
    const sinU2 = Math.sin(u2);
    const cosU1cosU2 = cosU1 * cosU2;
    const sinU1sinU2 = sinU1 * sinU2;

    let sigma = 0.0;
    // initial guess
    let lambda = l;

    let alpha1 = 0;

    for (var iter = 0; iter < this.MAXITERS; iter++) {
      const lambdaOrig = lambda;
      const cosLambda = Math.cos(lambda);
      const sinLambda = Math.sin(lambda);
      const t1 = cosU2 * sinLambda;
      const t2 = cosU1 * sinU2 - sinU1 * cosU2 * cosLambda;

      // (14)
      const sinSqSigma = t1 * t1 + t2 * t2;
      const sinSigma = Math.sqrt(sinSqSigma);
      // (15)
      const cosSigma = sinU1sinU2 + cosU1cosU2 * cosLambda;
      // (16)
      sigma = Math.atan2(sinSigma, cosSigma);
      // (17)
      const sinAlpha = this.calculateSinAlpha(sinSigma, cosU1cosU2, sinLambda);
      const cosSqAlpha = this.DOUBLE_1_0 - sinAlpha * sinAlpha;
      // (18)
      const cos2SM = this.calculateCos2SM(cosSqAlpha, cosSigma, sinU1sinU2);

      // (10)
      let c = (this.DOUBLE_F / this.DOUBLE_16_0)
              * cosSqAlpha
              * (this.DOUBLE_4_0 + this.DOUBLE_F * (this.DOUBLE_4_0 - (this.DOUBLE_3_0 * cosSqAlpha)));

      // (11)
      lambda = l + (this.DOUBLE_1_0 - c) * this.DOUBLE_F * sinAlpha
              * (sigma + c * sinSigma
                      * (cos2SM + c * cosSigma
                              * (this.DOUBLE_MINUS_1_0 + (this.DOUBLE_2_0 * cos2SM * cos2SM))));

      const delta = (lambda - lambdaOrig) / lambda;
      // (20)
      if (Math.abs(delta) < this.DOUBLE_1_0E_12) {
          alpha1 = Math.atan(t1 / t2);
          break;
      }
    }
    const latDistance = toLat - fromLat;

    let direction = 0;

    if (latDistance >= 0) {
      //北
      direction = alpha1 / (this.DOUBLE_PI_DIVIDE_180_0);
    } else {
      //南
      direction = alpha1 / (this.DOUBLE_PI_DIVIDE_180_0) + this.DOUBLE_180_0;
    }

    if (direction < 0) {
      direction = direction + this.DOUBLE_360_0;
    }
    return direction;
  }

  /** */
  private calculateSinAlpha(sinSigma: number, cosU1cosU2: number, sinLambda: number) {
    if (sinSigma == 0.0) {
      return 0.0;
    } else {
      return cosU1cosU2 * sinLambda / sinSigma;
    }
  }

  /** */
  private  calculateCos2SM(cosSqAlpha: number, cosSigma: number, sinU1sinU2: number) {
    if (cosSqAlpha == 0.0) {
      return 0.0;
    } else {
      return cosSigma - this.DOUBLE_2_0 * sinU1sinU2 / cosSqAlpha;
    }
  }

}
